#selectedUser {
    // box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.75rem;
    // padding: 1rem 0.75rem;
    position: relative;

    .user {
        &--details {
            line-height: 1.25em;
        }

        &--name {
            font-size: calc(18rem / 16);
            font-weight: bold;
        }
        &--meta {
            color: #212529;
            font-size: calc(16rem / 16);
        }
        &--profile-photo {
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    .button {
        &__remove {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            fill: rgb(255, 44, 44);

            &:hover {
                fill: rgb(255, 0, 0);
                cursor: pointer;
            }
        }
    }
}
