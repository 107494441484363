#EnrollmentForm {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 1rem;
    &.loading {
        text-align: center;
    }
    .form-group {
        .campaign {
            border: 1px solid #ced4da;
            border-radius: 5px;
            padding: 1rem 0.75rem;
            &__name {
                font-weight: bold;
                font-size: calc(16rem / 16);
            }
            &__description {
                font-size: calc(15rem / 16);
            }
        }
        &__select-a-procedure {
            .form-check {
                line-height: 1.5em;
                padding-left: 2.25rem;
                margin-bottom: 0.4rem;
                .form-check-input {
                    margin-top: 0.4rem;
                }
                &:nth-child(2n + 2) {
                    border: 1px solid rgba(163, 206, 235, 0.5);

                    &:hover {
                        border: 1px solid rgba(163, 206, 235, 1);
                    }

                    .form-check-label {
                    }
                }
                &:nth-child(3n + 3) {
                    border: 1px solid rgba(164, 235, 170, 0.5);

                    &:hover {
                        border: 1px solid rgba(164, 235, 170, 1);
                    }
                    .form-check-label {
                    }
                }
                .form-check-label {
                    // display: inline-block;

                    // border-radius: 10px;
                    padding: 0 8px 1px;
                }
            }
        }
    }
    .table {
        tbody {
            td {
                font-size: calc(15em / 16);
            }
        }
    }
    .tooltip__toggle {
        color: #007bff;
        &:hover {
            color: #0056b3;
            cursor: pointer;
        }
    }
    .user {
        align-items: center;
        display: flex;
        line-height: 1.15rem;

        &--meta {
            color: grey;
            font-size: calc(15rem / 16);
        }
        &--specialty::before {
            content: " ";
        }
        &--profile-photo {
            background-color: #e2e2e2;
            border-radius: 3px;
            height: 45px;
            overflow: hidden;
            margin: 0 0.5rem 0 0;
            width: 45px;

            &--image {
                width: 100%;
            }
        }
    }
    .form-check-label {
        &:hover {
            cursor: pointer;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__triangle {
        left: 20px !important;
    }

    input[type="file" i]::-webkit-file-upload-button {
        background-color: #6c757d;
        border-color: #6c757d;
        border-radius: 0.25rem;
        border: none;
        color: #fff;
        font-weight: 400;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        &:hover {
            cursor: pointer;
        }
    }

    #importCsv {
        display: block;
        margin: 0 0 1rem 0;
    }

    #procedureDate {
        box-sizing: border-box;
        outline: 0;
        padding: 0.75rem;
        position: relative;
        width: 100%;
    }

    .tab__enrollment {
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        padding: 1rem;
        border-radius: 0 0 5px 5px;
    }
}
