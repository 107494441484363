@import "~bootstrap/scss/bootstrap";

.EnrollmentFormApp {
    max-width: 994px;
}
.popover {
    max-width: 400px;

    ul,
    ol {
        margin: 0 0 0.25rem;
        padding-left: 1rem;
    }
}
table {
    caption {
        color: #333333;
        caption-side: top;
        font-weight: bold;
    }
}
